import styled from 'styled-components';
import { mediaFHD, mediaTablet } from './setting';

export const Container = styled.div`
	padding: 0 2rem;
	@media ${mediaTablet} {
		margin: 0 auto;
		max-width: 1360px;
		padding: 0 6rem;
	}

	@media ${mediaFHD} {
		max-width: 1850px;
		padding: 0 9rem;
	}
`;

export const Icon = styled.span`
	display: inline-block;
	vertical-align: middle;
	height: 1em;
	margin: 0 -0.25rem;
	position: relative;
	& img {
		display: block;
		position: relative;
		top: -3px;
	}
`;

export const IconText = styled.span`
	margin-left: 1rem;
`;
