export const colors = {
	brand: '#66A81C',
	brandLight: '#6ebd17',
	brandDark: '#417A00',
	gray: '#f4f4f4',
};

export const animation = {
	fast: '0.22s ease',
	slow: '0.6s ease-out',
	elastic: '0.33s cubic-bezier(.48,2,.37,.95)',
};

export const mediaFHD = '(min-width: 1700px)';
export const mediaDesktop = '(min-width: 901px)';
export const mediaTablet = '(min-width: 700px)';
export const mediaMobile = '(max-width: 900px)';
export const mediaMobileMini = '(max-width: 600px)';
