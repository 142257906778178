import React from 'react';
import styled, { css } from 'styled-components';
import { mediaMobile, animation, mediaDesktop, colors } from '../components/setting';
import { Page } from '../types';
import { Link } from 'gatsby';
// import Link from "gatsby-link";

type NavProps = {
	onLinkClick(): void;
	isOpen: boolean;
	categories: Page[] | null;
};

export const Nav = (props: NavProps) => {
	const sortByOrder = (a: Page, b: Page) => a.order - b.order;
	const mainCategories = props.categories.sort(sortByOrder).filter(item => !item.category);
	const getSubItems = (cat: Page) =>
		props.categories
			.sort(sortByOrder)
			.filter(item => item.category && item.category.slug === cat.slug);
	const hasSubItems = (cat: Page) =>
		props.categories.some(item => item.category && item.category.slug === cat.slug);

	return (
		<Container isOpen={props.isOpen}>
			<ul>
				{/* <li>
                    <Link
                        onClick={props.onLinkClick}
                        to="/"
                        activeClassName="link--active"
                    >
                        Home
                    </Link>
                </li> */}

				{mainCategories.map(main => (
					<li key={main.slug}>
						<Link
							onClick={props.onLinkClick}
							to={`/${main.slug}/`}
							activeClassName="link--active"
							partiallyActive>
							{main.title}
						</Link>

						{hasSubItems(main) && (
							<Sub>
								{getSubItems(main).map(subEl => (
									<li key={subEl.id}>
										<Link
											onClick={props.onLinkClick}
											to={`/${main.slug}/${subEl.slug}/`}
											activeClassName="link--active"
											partiallyActive>
											{subEl.title}
										</Link>
									</li>
								))}
							</Sub>
						)}
					</li>
				))}
				{/* <li>
                    <Link
                        onClick={props.onLinkClick}
                        to="/kontakt/"
                        activeClassName="link--active"
                    >
                        Kontakt
                    </Link>
                </li> */}
			</ul>
		</Container>
	);
};

const Container = styled.nav<{ isOpen: boolean }>`
	text-align: center;
	align-items: center;
	display: flex;
	flex-direction: column;
	& > ul {
		flex: 1;
		justify-content: space-around;
		display: flex;
		flex-direction: column;
		margin: 0;
	}

	@media ${mediaMobile} {
		transition: opacity ${animation.fast}, visibility ${animation.fast};
		height: 100vh;
		position: fixed;
		z-index: -1;
		justify-content: space-around;
		top: 60px;
		overflow-y: auto;
		padding: calc(20px + 10vh) 0 calc(50px + 15vh);
		left: 0;
		right: 0;
		background: black;

		${props =>
			props.isOpen
				? css`
						visibility: visible;
						opacity: 1;
				  `
				: css`
						visibility: hidden;
						opacity: 0;
				  `}
	}
	@media ${mediaDesktop} {
		flex-direction: row;
		ul {
			display: flex;
			flex-direction: row;
		}
	}
	& a {
		padding: 2rem;
		text-transform: uppercase;
		display: block;
		color: white;
		text-decoration: none;
		transition: color ${animation.fast}, opacity ${animation.slow};
		position: relative;
	}

	@media ${mediaMobile} {
		li {
			${props => {
				if (props.isOpen) {
					return css`
						opacity: 1;
						&:nth-child(1) a {
							transition: color ${animation.fast},
								opacity ${animation.slow} ${1 / 13}s;
						}
						&:nth-child(2) a {
							transition: color ${animation.fast},
								opacity ${animation.slow} ${2 / 13}s;
						}
						&:nth-child(3) a {
							transition: color ${animation.fast},
								opacity ${animation.slow} ${3 / 13}s;
						}
						&:nth-child(4) a {
							transition: color ${animation.fast},
								opacity ${animation.slow} ${4 / 13}s;
						}
						&:nth-child(5) a {
							transition: color ${animation.fast},
								opacity ${animation.slow} ${5 / 13}s;
						}
						&:nth-child(6) a {
							transition: color ${animation.fast},
								opacity ${animation.slow} ${6 / 13}s;
						}
					`;
				} else {
					return css`
						& a {
							opacity: 0;
							transition: color ${animation.fast}, opacity ${animation.fast};
						}
					`;
				}
			}}
		}
	}

	& a::after {
		content: '';
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		margin: auto;
		background: ${colors.brand};
		height: 0.2rem;
		width: 2rem;
		transform: scaleX(0);
		transition: transform ${animation.fast};
	}
	& li:hover > a {
		color: ${colors.brandLight} !important;
		&::after {
			transform: scaleX(0.5);
		}
	}
	& li > a:active::after {
		transform: scaleX(0.35);
	}
	& li a.link--active {
		color: ${colors.brand};
		&::after {
			transform: scaleX(1);
			transition: transform ${animation.elastic};
		}
		&:active::after {
			transform: scaleX(0.8);
		}
	}
`;

const Sub = styled.ul`
	display: none;
	margin: 0;
	@media ${mediaDesktop} {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		background: rgba(0, 0, 0, 0.8);
		position: absolute;
		top: 100%;
		left: 0;
		right: 0;
		padding: 0 6rem;
		opacity: 0;
		visibility: hidden;
		overflow: hidden;
		transform: translateY(-10px);
		transform-origin: 50% 0%;
		transition: opacity ${animation.fast} 0.3s, visibility ${animation.fast} 0.3s,
			transform ${animation.fast} 0.3s;
		li:hover & {
			opacity: 1;
			visibility: visible;
			overflow: hidden;
			transform: scaleY(1);
			transition: opacity ${animation.fast}, visibility ${animation.fast},
				transform ${animation.fast};
		}
	}
`;
