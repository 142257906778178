import styled, { StyledComponent } from 'styled-components';
import { colors, animation } from './setting';
import React from 'react';
import { Link } from 'gatsby';

// export const Button = (props: {icon?: string, children?: any}) => {
//     return (
//         <ButtonStyled>
//             {props.icon&&<Icon><img src={props.icon} alt="Icon"/></Icon>}
//             {props.children&&props.children}
//         </ButtonStyled>
//     );
// };

export const Button = styled.button`
    position: relative;
    display: inline-block;
    align-items: center;
    font-size: 1.6rem;
    background: ${colors.brand};
    color: #fff;
    transition: all ${animation.fast};
    padding: 1.1rem 1.7rem 1rem;
    border: none;
    cursor: pointer;
    border-radius: 0.4rem;
    min-height: 3.8rem;
    text-align: center;
    font-weight: 500;
    min-width: 4.6rem;
    text-transform: uppercase;

    &:hover:not(.deActive),
    &:focus:not(.deActive),
    &.active {
        background: ${colors.brandLight};
        color: #fff;
        box-shadow: 0 3px 20px -5px ${colors.brand};
    }
    &:active {
        box-shadow: 0 3px 20px -5px rgba(0, 0, 0, 0.5) inset;
        background: ${colors.brandDark};
        color: #fff;
    }
`;

export const LinkButton = Button as StyledComponent<typeof Link, {}>;
export const AButton = Button as StyledComponent<'a', {}>;
