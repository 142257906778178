import React, { useEffect, useState } from 'react';
import { Container, Icon } from '../components/base';
import logo from '../assets/logo.png';
import phoneIcon from '../assets/phone.svg';
import fbIcon from '../assets/facebook.svg';
import { Link } from 'gatsby';
import styled, { css } from 'styled-components';
import { animation, colors, mediaDesktop, mediaMobile } from '../components/setting';
import { AButton, Button } from '../components/button';
import { Page } from '../types';
import { Nav } from './nav';

export const Header = (props: { pathname: string; categories: Page[] }) => {
    // const [count, setCount] = useState(0);
    const [openHamburger, setOpenHamburger] = useState(false);

    return (
        <Top>
            <Container>
                <TopIn>
                    <Hamburger
                        onClick={() => setOpenHamburger(openHamburger ? false : true)}
                        isopen={openHamburger}
                        className={openHamburger ? 'active' : 'deActive'}
                    />
                    <Logo>
                        <Link to="/" onClick={() => setOpenHamburger(false)}>
                            <img src={logo} alt="Progress drzwi" />
                        </Link>
                    </Logo>
                    <Nav
                        isOpen={openHamburger}
                        categories={props.categories}
                        onLinkClick={() => setOpenHamburger(false)}
                    />
                    <CallButton>
                        <a href="https://www.facebook.com/ProgressDrzwi/" target="_blank" rel="noopener noreferrer">
                            <FacebookIcon>
                                <img src={fbIcon} alt="Facebook" />
                            </FacebookIcon>
                        </a>
                        <AButton as="a" href="tel: 570 999 401">
                            <Icon>
                                <img src={phoneIcon} alt="" />
                            </Icon>
                            <Number>570 999 401</Number>
                        </AButton>
                    </CallButton>
                </TopIn>
            </Container>
        </Top>
    );
};

const Top = styled.header`
    text-align: center;
    color: white;
    background-color: black;
    position: fixed;
    left: 0;
    top: 0px;
    width: 100%;
    z-index: 100;
`;

const TopIn = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    min-height: 6rem;
    @media ${mediaDesktop} {
        min-height: 8rem;
    }
`;

const Logo = styled.div`
    margin: 0;
    padding: 0 1rem;
    max-width: 65%;
    a {
        color: white;
        color: white;
        font-size: 2.4rem;
        font-weight: bold;
        text-decoration: none;
    }
`;

const CallButton = styled.div`
    text-align: right;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    @media ${mediaDesktop} {
        min-width: 178px;
    }
`;

export const FacebookIcon = styled.span`
    display: inline-block;
    vertical-align: middle;
    width: 2.5rem;
    margin: 0 2rem;
    position: relative;
`;

const Number = styled.span`
    display: none;
    @media ${mediaDesktop} {
        display: inline-block;
        padding-left: 1rem;
    }
`;
const Hamburger = styled(Button)<{ isopen: boolean }>`
    display: none;
    /* padding: 10px; */
    @media ${mediaMobile} {
        display: block;
        &::after,&::before {
            background: white;
            content: "";
            position: absolute;
            top: 0;
            bottom: 0;
            height: 0.2rem;
            border-radius: 0.2rem;
            margin: auto;
            left: 0;
            right: 0;
            width: 2.3rem;
            transition: transform ${animation.fast}, box-shadow ${animation.fast};
        }
        &::after {
            transform: translateY(-0.7rem);
            box-shadow: 0 0.7rem 0 0 white;
        }
        &::before {
            transform: translateY(0.7rem);
        }

        &.active::after {
            transform: translateY(0) rotate(45deg);
            box-shadow: 0 0rem 0 0 white;
        }
        &.active::before {
            transform: translateY(0) rotate(-45deg);
        }
        /* background: ${(props) => (props.isopen ? `green` : `black`)}; */
    }
`;
