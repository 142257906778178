import React from 'react';
import styled from 'styled-components';
import { Container } from '../components/base';
import logo from '../assets/logo.png';
import fbIcon from '../assets/facebook.svg';
import { Link } from 'gatsby';
import { Page } from '../types';
import { colors, mediaDesktop, mediaMobile, mediaMobileMini } from '../components/setting';

type FooterProps = {
	categories: Page[] | null;
};

export const Footer = (props: FooterProps) => {
	const sortByOrder = (a: Page, b: Page) => a.order - b.order;
	const mainCategories = props.categories.sort(sortByOrder).filter(item => !item.category);

	return (
		<Content>
			<Container>
				<Row>
					<Col>
						<h5>
							<Link to="/">
								<img src={logo} alt="Progress drzwi" />
							</Link>
						</h5>
					</Col>
					<Col>
						<h5>Menu:</h5>
						<ul>
							{mainCategories.map(main => (
								<li key={main.slug}>
									<Link to={`/${main.slug}/`}>{main.title}</Link>
								</li>
							))}
						</ul>
					</Col>
					<Col>
						<h5>Kontakt:</h5>
						<p>
							<span>ul. Kiedrzyńska 54</span>
							<span>42-200 Częstochowa</span>
							<span>
								tel. <a href="tel: 570 999 401">570 999 401</a>
							</span>
							<span>
								e-mail:
								<a href="mailto: progress@drzwi.czest.pl">
									progress@drzwi.czest.pl
								</a>
							</span>
						</p>
					</Col>
					<Collast>
						<h5>
							<a
								href="https://www.facebook.com/ProgressDrzwi/"
								target="_blank"
								rel="noopener noreferrer">
								Znajdz nas na
								<img src={fbIcon} alt="Facebook" />
							</a>
						</h5>
					</Collast>
				</Row>
				<Copy>
					<div>Copyright ©2019 drzwi.czest.pl</div>
					<div>
						<a href="https://KozakBartosz.pl/" target="_blank">
							Developed by: KozakBartosz.pl
						</a>
					</div>
				</Copy>
			</Container>
		</Content>
	);
};

const Content = styled.footer`
	margin: 0 auto;
	color: white;
	background: black;
	margin-top: 6rem;
	padding: 4rem 0 0;
	font-size: 1.4rem;

	& a {
		color: white;
	}
	& a:hover {
		color: ${colors.brand};
	}
`;

const Row = styled.div`
	display: flex;
	@media ${mediaMobile} {
		flex-wrap: wrap;
	}
`;

const Col = styled.div`
	padding-right: 5rem;
	width: 30rem;
	& img {
		display: inline-block;
	}
	@media ${mediaMobile} {
		width: 50%;
		&:first-child {
			width: 100%;
			text-align: center;
			padding-right: 0;
			margin-bottom: 4rem;
		}
	}
	@media ${mediaMobileMini} {
		width: 100%;
		text-align: center;
		padding-right: 0;
	}
	line-height: 1.7em;
	max-width: 100%;
	& span {
		display: block;
	}
`;

const Collast = styled.div`
	width: 30%;
	text-align: right;
	& img {
		width: 2.2rem;
		display: inline-block;
		position: relative;
		top: 0.4rem;
		margin-left: 1rem;
	}
	@media ${mediaMobile} {
		text-align: center;
		width: 100%;
		display: block;
	}
`;
const Copy = styled.div`
	margin-top: 3rem;
	padding: 1.3rem 0;
	text-align: center;
	border-top: 1px solid #1e1e1e;

	@media ${mediaDesktop} {
		display: flex;
		justify-content: space-between;
	}

	a:hover {
		color: #00a3ff;
		background-image: linear-gradient(105.31deg, #00a3ff 0%, #00ffa3 100%);
		color: transparent;
		background-clip: text;
		-webkit-background-clip: text;
	}
`;
