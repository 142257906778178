import React from 'react';
import styled from 'styled-components';
import { graphql, StaticQuery } from 'gatsby';
// import Link from "gatsby-link";
// import SiteMetadata from './meta';
// import SiteMetadata from "./metadato";
import Transition from './transition';
import { Header } from './header';
import { GlobalStyle } from './globalStyle';
import { Footer } from './footer';
import { Page } from '../types';
import { mediaDesktop } from '../components/setting';

const Layout = (props: {
	children: React.ReactNode;
	location: { pathname: string };
	pageContext: { animation?: string };
}) => (
	<>
		<GlobalStyle />
		<StaticQuery
			query={graphql`
				query MyQuery {
					allDatoCmsPage {
						nodes {
							id
							slug
							title
							order
							category {
								slug
							}
						}
					}
				}
			`}
			render={(data: { allDatoCmsPage: { nodes: Page[] } }) => (
				<>
					{console.log('layoutprops: ', data)}
					<Header
						pathname={props.location.pathname}
						categories={data.allDatoCmsPage.nodes}
					/>

					<Main>
						{/* <h2>Animation: {JSON.stringify(props.location.state.animation, null, 2)}</h2> */}
						<Transition location={props.location} pageContext={props.pageContext}>
							{props.children}
						</Transition>
					</Main>
					<Footer categories={data.allDatoCmsPage.nodes} />
				</>
			)}
		/>
	</>
);

const Main = styled.main`
	min-height: calc(100vh - 8rem - 35.1rem);
	overflow: hidden;
	margin-top: 6rem;
	@media ${mediaDesktop} {
		margin-top: 8rem;
	}
	position: relative;
`;

export default Layout;
