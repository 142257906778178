import React from 'react';
import { TransitionGroup, Transition as ReactTransition } from 'react-transition-group';
import styled, { css, keyframes } from 'styled-components';

//This variable will be responsible for our animation duration
const timeout = 300;

//This object contains basic styles for animation, but you can extend them to whatever you like. Be creative!
type TransitionType = {
	children: React.ReactNode;
	location: { pathname: string };
	pageContext: {
		animation?: string;
		layout?: string;
	};
};

const Transition = (props: TransitionType) => {
	//Destructuring props to avoid garbage this.props... in return statement
	// console.log("transition.location",props);
	let index = 1;
	return (
		//Using TransitionGroup and ReactTransition which are both
		//coming from  'react-transition-group' and are required for transitions to work
		<TransitionGroup>
			<ReactTransition
				//the key is necessary here because our ReactTransition needs to know when pages are entering/exiting the DOM
				key={props.location.pathname}
				//duration of transition
				timeout={{
					enter: timeout,
					exit: timeout,
				}}>
				{//Application of the styles depending on the status of page(entering, exiting, entered) in the DOM
				status => <AnimationPage status={status}>{props.children}</AnimationPage>}
			</ReactTransition>
		</TransitionGroup>
	);
};

// const getTransitionStyles = {
// 	entering: {
// 		transition: `all ${timeout}ms ease-in-out`,
// 		position: 'absolute',
// 		opacity: 0,
// 		transform: `scale(1.1)`,
// 	},
// 	entered: {
// 		transition: `all ${timeout}ms ease-in-out`,
// 		opacity: 1,
// 		transform: `scale(1)`,
// 	},
// 	exiting: {
// 		transition: `all ${timeout}ms ease-in-out`,
// 		opacity: 0,
// 		transform: `scale(0.9)`,
// 	},
// };

const entering = keyframes`
  0% {
	transform: translateY(20px);
	opacity: 0;
  }
  100% {
	transform: translateY(0px);
	opacity: 1;
  }
`;

const exiting = keyframes`
  0% {
	transform: translateY(0px);
	opacity: 1;
  }
  100% {
	transform: translateY(-30px);
	opacity: 0;
  }
`;

const AnimationPage = styled.div<{ status: string }>`
	/* transition: transform ${timeout}ms ease, opacity ${timeout}ms ease; */
	will-change: transform, opacity;
	/* transform-origin: 50% 50vw; */
	top: 0;
	left: 0;
	width: 100%;
	position: relative;
	z-index: 10;

	${props => {
		if (props.status == 'entering') {
			return css`
				position: absolute;
				z-index: 11;
				opacity: 0;
				/* animation: ${entering} ${timeout * 3}ms ease both; */
				/* animation-delay: ${timeout}ms; */
			`;
		} else if (props.status == 'entered') {
			return css`
				/* transform: translateY(-20px); */
				opacity: 1;
				animation: ${entering} ${timeout * 3}ms ease both;
				/* animation-delay: ${timeout}ms; */
			`;
		} else if (props.status == 'exiting') {
			return css`
				opacity: 0;
				animation: ${exiting} ${timeout}ms ease both;
				z-index: 7;
			`;
		}
	}}
`;

export default Transition;
